import React, { useState, useEffect } from 'react'
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/authActions';
import { useParams, Link } from 'react-router-dom';
import Auth from '../../assets/auth.svg'
import { Helmet } from 'react-helmet';


const Reset = ({ authState: { authLoading, resetSuccess, resetres }, error, resetPassword }) => {

    let { token } = useParams();
    const [data, setData] = useState({
        password: '',
        password_confirmation: '',
        token: '',
    })


    const onChange = e => setData({ ...data, [e.target.name]: e.target.value });
    const handleSubmit = (e) => {
        e.preventDefault();
        resetPassword({
            email: data.email,
            password: data.password,
            password_confirmation: data.password_confirmation,
            token: token,
        });
        console.log(data);
    }

    useEffect(() => {
        if (resetSuccess) {
            setData(
                {
                    password: '',
                    password_confirmation: '',
                    token: '',
                }
            );
        }
    }, [resetSuccess])

    return (
        <div>
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <Container fluid={true}>
                <Row>
                    <Col md="4" className="authAside d-flex align-items-center">
                        <div className="p-5">
                            <img src={Auth} alt="auth" width="100%" />
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                        <div className="login-form ">
                            <div className="mb-3">
                                <h3>Reset Password</h3>
                                <span className="text-muted">Enter new password below. <Link to="/login" className="text-primary">Login</Link></span>
                            </div>
                            {error.message.reset && <Alert color="danger">{error.message.reset}</Alert>}
                            {resetres && <Alert color="success">{resetres.reset}</Alert>}
                            <Form onSubmit={handleSubmit} action="">
                                <FormGroup>
                                    <Label for="password">New Password</Label>
                                    <Input type="password" name="password" id="password" value={data.password} onChange={onChange} placeholder="Enter password" invalid={error.message.password} />
                                    {error.message.password && <FormFeedback>{error.message.password}</FormFeedback>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="confirm_password">Confirm Password</Label>
                                    <Input type="password" name="password_confirmation" value={data.password_confirmation} onChange={onChange} id="confirm_password" placeholder="Confirm password" />
                                </FormGroup>
                                <Button type="submit" color="primary" disabled={authLoading}>{authLoading ? "Loading..." : "Update"}</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
const mapStateTopProps = state => ({
    authState: state.auth,
    error: state.error
})
export default connect(mapStateTopProps, { resetPassword })(Reset)
