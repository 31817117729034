import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { updateGroup } from '../../actions/groupsActions';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback
} from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Edit = ({ group, updateGroup, error, loading }) => {

    let history = useHistory();

    useEffect(() => {
        if (!group) {
            history.goBack()
        }
    }, [])

    const [magroup, setGroup] = useState({
        name: group.name,
        description: group.description
    })

    useEffect(() => {
        if (group.length === 0) {
            history.push('/dashboard/groups');
        }
    }, [])

    const onChange = (e) => {
        setGroup({ ...magroup, [e.target.name]: e.target.value })
    }
    const [image, setImage] = useState({ preview: "", raw: "" });
    const handleChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = new FormData();
        data.append("name", magroup.name);
        data.append("description", magroup.description);
        data.append("featured", image.raw);
        updateGroup(data, group.id);
        // console.log({ magroup, image });
    }



    return (
        <div>
            <Helmet>
                <title>{group.name}</title>
            </Helmet>
            <div className="heading mt-5">
                <h2>Edit {group.name} Group</h2>
                <p className="my-4"><Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/groups">Groups</Link> / {group.name}</p>
            </div>
            <Card className="card-custom">
                <CardHeader>
                    <div className="card-title mt-4">
                        <h3 className="card-label">
                            {group.name}
                        </h3>
                    </div>
                </CardHeader>
                <CardBody>

                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="groupName">Group name</Label>
                            <Input type="text" name="name" id="groupName" onChange={onChange} placeholder="Enter group name" value={magroup.name} invalid={error.message.name} />
                            {error.message.name && <FormFeedback>{error.message.name}</FormFeedback>}
                            <span className="form-text text-muted">e.g. clients or employees</span>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="upload-button" className="img-upload-btn">
                                Group Image <br />
                                <br />
                                {image.preview ? (
                                    <>
                                        <img src={image.preview} alt="dummy" className="rounded mb-2" height="90px" width="90px" />
                                        <p className="img-name">{image.raw.name}</p>
                                    </>
                                ) : (
                                    <>
                                        <img src={group.featured !== "uploads/groups/" ? `https://backend.notifyza.co.za/${group.featured}` : "https://via.placeholder.com/150"} alt="..." className="rounded mb-2" height="90px" width="90px" />
                                        <p className="img-name">Upload your photo (optional)</p>
                                    </>
                                )}
                            </Label>
                            <input
                                type="file"
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={handleChange}
                            />
                            {/* <span className="form-text text-muted">Optional, you can update a group without an image</span> */}
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input type="textarea" rows="5" name="description" id="description" value={magroup.description} onChange={onChange} placeholder="Enter group description" invalid={error.message.description} />
                            {error.message.description && <FormFeedback>{error.message.description}</FormFeedback>}
                        </FormGroup>
                        <Button color="primary" type="submit">{loading ? "Loading..." : "Update"}</Button>
                    </Form>

                </CardBody>
            </Card>
        </div>
    )
}
const mapStateToProps = state => ({
    group: state.groups.group,
    loading: state.groups.groups_Loading,
    error: state.error
})
export default connect(mapStateToProps, { updateGroup })(Edit)
