import axios from 'axios';
import { returnErrors } from './errorActions'
import {
    CONTACTS_LOADING,
    GET_CONTACTS,
    CREATE_CONTACTS,
    DELETE_CONTACTS,
    Add_LOADING,
    CONTACTS_ERROR,
    CLEAR_ERRORS,
    CLEAR_CONTACT_SUCCESS,
    IMPORT_LOADING,
    IMPORT_CONTACTS
} from '../actions/types';
import { toast } from 'react-toastify';

const url = 'https:///backend.notifyza.co.za/api';

// Get Contacts
export const getContacts = (slug) => async dispatch => {

    // contacts Loading
    dispatch({ type: CONTACTS_LOADING });

    // console.log(data);

    try {
        const res = await axios.get(`${url}/v2/groups/${slug}/contacts`);
        dispatch({
            type: GET_CONTACTS,
            payload: res.data
        })
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'CONTACTS_ERROR'));
        dispatch({
            type: CONTACTS_ERROR
        })
    }
}

// Create Contact
export const createContacts = (data) => async dispatch => {

    // contacts Loading
    dispatch({ type: Add_LOADING });

    // console.log(data);

    try {
        const res = await axios.post(`${url}/v2/contacts`, data);
        dispatch({
            type: CREATE_CONTACTS,
            payload: res.data
        })
        setTimeout(() => dispatch({ type: CLEAR_CONTACT_SUCCESS }), 5000);
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'CONTACTS_ERROR'));
        dispatch({
            type: CONTACTS_ERROR
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}
// Import Contacts
export const importFile = (file) => async dispatch => {

    // contacts Loading
    dispatch({ type: IMPORT_LOADING });

    // console.log(data);

    try {
        const res = await axios.post(`${url}/v2/contacts/import`, file, {
            headers: {
                "content-type": "multipart/form-data"
            }
        });
        dispatch({
            type: IMPORT_CONTACTS,
            payload: res.data
        })
        setTimeout(() => dispatch({ type: CLEAR_CONTACT_SUCCESS }), 5000);
        toast.success("Contacts imported");
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'CONTACTS_ERROR'));
        dispatch({
            type: CONTACTS_ERROR
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}

// Create Contact
export const removeContacts = (id) => async dispatch => {
    try {
        await axios.delete(`${url}/v2/contacts/${id}`);
        dispatch({
            type: DELETE_CONTACTS,
            payload: id
        })

        toast.warning("Contact removed");
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'CONTACTS_ERROR'));
        dispatch({
            type: CONTACTS_ERROR
        })
    }
}