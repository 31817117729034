import React, { useState, useEffect, useRef } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Tooltip,
    FormFeedback,
    Alert
} from 'reactstrap';
// import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { getGroupList } from '../../actions/groupsActions';
import { sendBulk } from '../../actions/smsActions';
import { Helmet } from 'react-helmet';
import Switch from "react-switch";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Bulk = ({ groupsState: { list, groups_Loading }, getGroupList, sendBulk, loading, error, success }) => {




    let sendSelect = useRef();
    const [message, setMessage] = useState('');
    const [groups, setGroups] = useState([])
    // const [schedule, setSchedule] = useState(false)
    // const [date, setDate] = useState('');

    // const handleSchedule = (checked) => {
    //     setSchedule(checked);
    // }

    const onchangeSelect = (item) => {
        setGroups(item);
    };

    useEffect(() => {
        getGroupList();
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = {
            groups: groups.map(group => group.value),
            messagefield: message
        }
        sendBulk(data);
    }

    const customStyles = {
        multiValue: (styles, { data }) => {

            return {
                ...styles,
                backgroundColor: "#0bb783",
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: 'white',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: 'white',
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),

        placeholder: (styles) => ({
            ...styles,
            fontSize: 12
        })
    }
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        if (success) {
            setMessage('');
            setGroups([]);
            sendSelect.current.state.value = [];
        }
    }, [success])

    return (
        <div>
            <Helmet>
                <title>Send - Bulk</title>
            </Helmet>
            <div className="heading mt-5">
                <h2>Bulk</h2>
                <p className="my-4"><Link to="/dashboard">Dashboard</Link> / send / bulk</p>
            </div>
            <Card className="card-custom">
                <CardHeader>
                    <div className="card-title mt-4">
                        <h3 className="card-label">
                            Send Message
                           </h3>
                        {/* <small>All the groups you have</small> */}
                    </div>
                </CardHeader>
                <CardBody>
                    {error.message.send && <Alert color="danger">{error.message.send}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="groupName">Groups ({groups.length})</Label>
                            <Select
                                ref={sendSelect}
                                isLoading={groups_Loading}
                                onChange={onchangeSelect}
                                closeMenuOnSelect
                                isMulti
                                options={list}
                                styles={customStyles}
                                placeholder="Select group (s)"
                            />
                            {error.message.groups && <div className="s_e d-flex align-items-center">{error.message.groups}  <ion-icon name="information-circle-outline"></ion-icon></div>}
                            <span className="form-text text-muted">You can select multiple groups at a time</span>
                        </FormGroup>

                        <FormGroup>
                            <Label for="text">Text (Message)</Label>
                            <Input type="textarea" rows="4" onChange={(e) => setMessage(e.target.value)} value={message} id="text" placeholder="Type message here " invalid={error.message.messagefield} />
                            {error.message.messagefield && <FormFeedback>{error.message.messagefield}</FormFeedback>}
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="form-text text-muted">{message.length} Characteres</span>
                                <div className="mt-1">
                                    <span id="DisabledAutoHideExample"><ion-icon name="help-circle-outline"></ion-icon></span>
                                    <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="DisabledAutoHideExample" toggle={toggle}>
                                        Long messages are devided into parts of 160 Characteres, messages with unicodes are 70 Characteres per part, and each part cost 1 sms
                                    </Tooltip>
                                </div>
                            </div>
                        </FormGroup>
                        {/* <FormGroup>
                            <Label for="text">Schedule Message</Label>
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center mr-5 py-2">
                                    <Switch
                                        onChange={handleSchedule}
                                        checked={schedule}
                                        onColor="#0bb783"
                                        offColor="#bdbdbd"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        activeBoxShadow="0"
                                    />
                                </div>
                                {schedule &&
                                    <div className="d-flex align-items-center">
                                        <DatePicker
                                            selected={date}
                                            closeOnScroll={true}
                                            onChange={date => setDate(date)}
                                            // isClearable
                                            placeholderText="Select Date and Time"
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            className="date-picker form-control"
                                        />
                                        <span className="ml-2 d-flex align-items-center">
                                            <ion-icon name="calendar-outline"></ion-icon>
                                        </span>
                                    </div>
                                }
                            </div>
                        </FormGroup> */}
                        <Button color="primary" type="submit" disabled={loading}>{loading ? "Loading..." : "Send"}</Button>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}
const mapStateToProps = state => ({
    groupsState: state.groups,
    loading: state.sms.send_Loading,
    success: state.sms.success,
    error: state.error
})
export default connect(mapStateToProps, { getGroupList, sendBulk })(Bulk)
