import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStore } from 'react-redux';

const Protected = ({ component: Component, ...rest }) => {
    const store = useStore();
    let isAuthenticated = store.getState().auth.isAuthenticated;

    return (
        <Route {...rest} render={
            (props) => {
                if (isAuthenticated) {
                    return <Component {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: "/login",
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        } />
    )
}

export default Protected