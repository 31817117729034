import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getGroups, removeGroup, setGroup } from '../../actions/groupsActions';
import {
    Table,
    Card,
    CardHeader,
    CardBody
} from 'reactstrap';
import { Helmet } from 'react-helmet';

const Groups = ({ getGroups, removeGroup, setGroup, groupsState: { groups_Loading, groups } }) => {

    useEffect(() => {
        getGroups();
    }, [])


    const handleRemove = (id) => {
        removeGroup(id);
    }

    return (
        <div>
            <Helmet>
                <title>Groups</title>
            </Helmet>
            <div className="heading mt-5">
                <h2>All Groups</h2>
                <p className="my-4"><Link to="/dashboard">Dashboard</Link> / Groups</p>
            </div>
            <Card className="card-custom">
                <CardHeader>
                    <div className="card-title mt-4">
                        <h3 className="card-label">
                            Groups
                        </h3>
                    </div>
                    <div class="card-toolbar">
                        <Link to="/dashboard/group/create" className="d-flex align-items-center pl-0" style={{ color: '#0BB783' }}><ion-icon name="add-outline" style={{ color: "#0BB783", transform: "rotate(180deg)" }}></ion-icon> <span className="ml-2">Create</span></Link>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table borderless responsive>
                        <thead>
                            <tr>
                                <th className="pl-0">Name</th>
                                <th className="g_d_h">Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups_Loading && groups ? <span>Loading...</span> :
                                groups.length ? (
                                    groups.map((group, index) =>
                                    (
                                        <tr key={index}>
                                            <td className="d-flex align-items-center">
                                                <figure className="avatar">
                                                    {
                                                        group.featured !== "uploads/groups/" ? <img src={`https://backend.notifyza.co.za/${group.featured}`} className="rounded-circle" alt="image" style={{ width: 40, height: 40 }}></img> : (
                                                            <span className="avatar-title rounded-circle">
                                                                <ion-icon name="people-circle-outline"></ion-icon>
                                                            </span>
                                                        )
                                                    }
                                                </figure>
                                                <Link to={`/dashboard/group/${group.slug}/contacts`} className="ml-3 text-muted d-flex align-items-center">
                                                    <span style={{ color: '#0BB783' }} className="mr-2">{group.name}</span></Link></td>
                                            <td className="g_d">
                                                <p className="group-description text-muted">
                                                    {group.description.length > 40 ? group.description.slice(0, 40) + '...' : group.description}
                                                </p>
                                            </td>
                                            <td className="d-flex align-items-center justify-content-center">
                                                <Link onClick={() => setGroup(group)} to={`/dashboard/group/${group.slug}`} className="btn" title="Edit"><ion-icon name="create-outline"></ion-icon></Link>
                                                <button onClick={() => handleRemove(group.id)} className="btn" title="Remove"><ion-icon name="trash-outline"></ion-icon></button>
                                            </td>
                                        </tr>
                                    )
                                    )) : <div className="text-muted">No groups found</div>
                            }
                        </tbody>
                    </Table>

                </CardBody>
            </Card>


        </div>
    )
}

const mapStateToProps = state => ({
    groupsState: state.groups

})

export default connect(mapStateToProps, { getGroups, removeGroup, setGroup })(Groups)
