import React, { useState } from 'react'
import {
    Navbar,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import {
    NavLink,
    useHistory,
} from "react-router-dom";
import { connect } from 'react-redux';
import { logout } from '../actions/authActions';
import Logo from '../assets/logo-2.png';

const Navigation = ({ auth: { user, credits }, logout, setShow, show }) => {

    let history = useHistory()

    const handleLogout = () => {
        logout();
        history.push('/login');
    }

    return (
        <div>
            <Navbar color="light" light expand="md">
                <div className=" d-flex align-items-center">
                    {show ?
                        <button className="btn px-0 o_n" onClick={() => setShow(false)}><ion-icon name="close-outline"></ion-icon></button>
                        :
                        <button className="btn px-0 o_n" onClick={() => setShow(true)}><ion-icon name="reorder-three-outline"></ion-icon></button>
                    }
                    <NavLink to="/dashboard" className="navbar-brand nav-link d-flex align-items-center"><img src={Logo} alt="notifyZA" width="100" /></NavLink>
                </div>
                {/* <NavbarToggler onClick={toggle} /> */}
                <Nav className="ml-auto pl-3" navbar>
                    <UncontrolledDropdown >
                        <DropdownToggle nav className="d-flex align-items-center">
                            <ion-icon name="person-sharp"></ion-icon> <span className="ml-2 ndss">{user.name}</span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => history.push('/dashboard/profile')}>
                                Profile ({credits.credits} SMSes)
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={handleLogout}>
                                Logout
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>

            </Navbar>
        </div >
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logout })(Navigation)
