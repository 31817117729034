import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import { forgotPassword } from '../../actions/authActions';
import Auth from '../../assets/auth.svg'
import { Helmet } from 'react-helmet';

const Forgot = ({ authState: { authLoading, fgtpres }, error, forgotPassword }) => {

    const [email, setEmail] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault();
        forgotPassword({ email });
    }


    return (
        <>
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>
            <Container fluid={true}>
                <Row>
                    <Col md="4" className="authAside d-flex align-items-center" style={{ height: "100vh" }}>
                        <div className="p-5">
                            <img src={Auth} alt="auth" width="100%" />
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "95vh" }}>

                            <div className="login-form ">
                                <div className="mb-3">
                                    <h3>Forgot Password?</h3>
                                    <span className="text-muted">Enter your email to reset your password. <Link to="/login" className="text-primary">Login</Link></span>
                                </div>
                                {fgtpres && <Alert color="success">{fgtpres.forgotPassword}</Alert>}
                                <Form onSubmit={handleSubmit} action="">
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input type="email" placeholder="Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)} invalid={error.message.email} />
                                        {error.message.email && <FormFeedback>{error.message.email}</FormFeedback>}
                                    </FormGroup>
                                    <Button type="submit" color="primary" disabled={authLoading}>{authLoading ? "Loading..." : "Send"}</Button>
                                </Form>
                            </div>
                        </div>


                        <div className="text-center">
                            <Link to="/">Home</Link>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}
const mapStateTopProps = state => ({
    authState: state.auth,
    error: state.error
})
export default connect(mapStateTopProps, { forgotPassword })(Forgot)
