import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { register } from '../../actions/authActions';
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback
} from 'reactstrap';
import Auth from '../../assets/auth.svg'
import { Helmet } from 'react-helmet';

const Register = ({ register, loading, error, isAuthenticated }) => {
    const history = useHistory();


    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
    })

    const onChange = e => setUser({ ...user, [e.target.name]: e.target.value });

    const handleSubmit = (e) => {
        e.preventDefault();

        // Attempt to register
        register(user);

    }

    useEffect(() => {
        let url = '/dashboard';


        if (history.location.state) {
            url = history.location.state.from.pathname
        }
        if (isAuthenticated) {
            history.push(url);
        }

    }, [isAuthenticated]);

    return (
        <>
            <Helmet>
                <title>Register</title>
            </Helmet>
            <Container fluid={true}>
                <Row>
                    <Col md="4" className="authAside d-flex align-items-center" style={{ height: "100vh" }}>
                        <div className="p-5">
                            <img src={Auth} alt="auth" width="100%" />
                        </div>
                    </Col>
                    <Col >
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "95vh" }}>

                            <div className="login-form ">
                                <div className="mb-3">
                                    <h3>Sign Up</h3>
                                    <span className="text-muted">Have an account? <Link to="/login" className="text-primary">Login</Link></span>
                                </div>
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label for="name">Name</Label>
                                        <Input type="text" placeholder="name" name="name" onChange={onChange} invalid={error.message.name} />
                                        {error.message.name && <FormFeedback>{error.message.name}</FormFeedback>}

                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input type="email" placeholder="email" name="email" onChange={onChange} invalid={error.message.email} />
                                        {error.message.email && <FormFeedback>{error.message.email}</FormFeedback>}

                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="password">Password</Label>
                                        <Input type="password" placeholder="password" name="password" onChange={onChange} invalid={error.message.password} />
                                        {error.message.password && <FormFeedback>{error.message.password}</FormFeedback>}
                                    </FormGroup>
                                    <Button type="submit" color="primary">{loading ? "Loading..." : "Register"}</Button>
                                </Form>
                            </div>
                        </div>


                        <div className="text-center">
                            <Link to="/">Home</Link>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.authLoading,
    error: state.error
});
export default connect(mapStateToProps, { register })(Register)
