import React, { useEffect } from 'react'
import {
    Table,
    Card,
    CardHeader,
    CardBody
} from 'reactstrap';
import { connect } from 'react-redux';
import { getMessage } from '../../actions/smsActions';
import { useParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Message = ({ contacts, loading, getMessage }) => {
    let { id } = useParams();
    useEffect(() => {
        getMessage(id);
    }, [])
    return (
        <div>
            <Helmet>
                <title>Message details</title>
            </Helmet>
            <div className="heading mt-5">
                <h2>Messages</h2>
                <p className="my-4"><Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/sent">sent</Link> / message / {id}</p>
            </div>
            <Card className="card-custom">
                <CardHeader>
                    <div className="card-title mt-4">
                        <h3 className="card-label">
                            Message Details
                        </h3>
                    </div>
                </CardHeader>
                <CardBody>

                    <Table borderless responsive>
                        <thead>
                            <tr>
                                <th className="c_h">contacts ({contacts.length})</th>
                                <th className="c_h">cost</th>
                                <th className="c_h text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && contacts ? <span>Loading...</span> :
                                contacts.length ? (
                                    contacts.map((contact, index) =>
                                    (
                                        <tr key={index}>
                                            <td>
                                                <div className="contact d-flex align-items-center">
                                                    <ion-icon name="call-outline"></ion-icon>
                                                    <span className="ml-3">
                                                        {contact.to}
                                                    </span>
                                                </div>
                                            </td>

                                            <td className="d-flex align-items-center">{contact.creditCost}<small style={{ color: '#0BB783' }} className="ml-2"> ({contact.encoding.toLowerCase()})</small></td>

                                            <td className="text-center">
                                                {
                                                    (() => {
                                                        if (contact.status.type === 'DELIVERED')
                                                            return <span title="Delivered"> <ion-icon style={{ color: '#0bb783' }} name="checkmark-done-circle-outline"></ion-icon></span>
                                                        if (contact.status.type === 'FAILED')
                                                            return <span title="FAILED"> <ion-icon style={{ color: '#dc3545' }} name="close-circle-outline"></ion-icon></span>
                                                        if (contact.status.type === 'SENT')
                                                            return <span title="SENT"> <ion-icon name="checkmark-circle-outline"></ion-icon></span>
                                                    })()
                                                }
                                            </td>
                                        </tr>
                                    )
                                    )) : <div className="text-muted" >Message reports are kept for a month.</div>
                            }
                        </tbody>
                    </Table>

                </CardBody>
            </Card>
        </div>
    )
}

const mapStateToProps = state => ({
    contacts: state.sms.message,
    loading: state.sms.message_loading
})
export default connect(mapStateToProps, { getMessage })(Message)
