import axios from 'axios';
import { returnErrors } from './errorActions'
import {
    GROUPS_LOADING,
    GET_GROUPS,
    CREATE_GROUPS,
    DELETE_GROUP,
    UPDATE_GROUP,
    GROUPS_ERROR,
    CLEAR_GROUP,
    SET_GROUP,
    GET_GROUPS_LIST,
    CLEAR_ERRORS,
    CLEAR_GROUP_SUCCESS
} from '../actions/types';
import { toast } from 'react-toastify';

const url = 'https:///backend.notifyza.co.za/api';

// Get Groups
export const getGroups = () => async dispatch => {

    // Groups Loading
    dispatch({ type: GROUPS_LOADING });

    try {
        const res = await axios.get(`${url}/v2/groups`);
        dispatch({
            type: GET_GROUPS,
            payload: res.data
        })
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'GROUPS_ERROR'));
    }
}
// Get Groups

export const getGroupList = () => async dispatch => {

    // Groups Loading
    dispatch({ type: GROUPS_LOADING });

    try {
        const res = await axios.get(`${url}/v2/groups/list`);
        dispatch({
            type: GET_GROUPS_LIST,
            payload: res.data
        })
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'GROUPS_ERROR'));
    }
}


// Create Group

export const createGroup = (group) => async dispatch => {

    // Groups Loading
    dispatch({ type: GROUPS_LOADING });

    try {
        const res = await axios.post(`${url}/v2/groups`, group, {
            headers: {
                "content-type": "multipart/form-data"
            }
        });
        dispatch({
            type: CREATE_GROUPS,
            payload: res.data
        })
        toast.success("Group created successfully");
        setTimeout(() => dispatch({ type: CLEAR_GROUP_SUCCESS }), 5000);

    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'GROUPS_ERROR'));
        dispatch({
            type: GROUPS_ERROR
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}


// Create Group

export const removeGroup = (id) => async dispatch => {

    // Groups Loading
    // dispatch({ type: GROUPS_LOADING });

    try {
        const res = await axios.delete(`${url}/v2/groups/${id}`);
        dispatch({
            type: DELETE_GROUP,
            payload: id
        })

        toast.warning("Group removed!");
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'GROUPS_ERROR'));
        dispatch({
            type: GROUPS_ERROR
        })
    }
}

// Set Current
export const setGroup = (group) => {

    return {
        type: SET_GROUP,
        payload: group
    }
}
// Clear Current
export const clearGroup = () => {

    return {
        type: CLEAR_GROUP
    }
}

// Create Group
export const updateGroup = (data, id) => async dispatch => {

    // Groups Loading
    dispatch({ type: GROUPS_LOADING });

    try {
        const res = await axios.post(`${url}/v2/groups/${id}`, data, {
            headers: {
                "content-type": "multipart/form-data"
            }
        });
        dispatch({
            type: UPDATE_GROUP,
            payload: res.data
        })

        toast.success("Group updated successfully");
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'GROUPS_ERROR'));
        dispatch({
            type: GROUPS_ERROR
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}
