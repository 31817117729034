import axios from 'axios';
import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    AUTH_LOADING,
    UPDATE_CREDITS,
    UPDATE_PASSWORD,
    CLEAR_UPDATE_PASSWORD_SUCCESS,
    PASSWORD_FAIL,
    UPDATE_USER,
    USER_FAIL,
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD,
    RESET_LOADING,
    RESET,
    RESET_FAIL,
    CLEAR_RESET_SUCCESS,
    STATS_LOADING,
    GET_STATS,
    STATS_ERROR,
    CONTACT,
    CONTACT_FAIL
} from '../actions/types';

const initialState = {
    credits: null,
    isAuthenticated: null,
    isLoading: true,
    statsLoading: false,
    authLoading: false,
    user: null,
    success: false,
    fgtpres: null,
    reset: {},
    r_loading: false,
    resetres: null,
    resetSuccess: false,
    stats: null,
    contact_res: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case AUTH_LOADING:
            return {
                ...state,
                authLoading: true
            }
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload.user,
                credits: action.payload.credits
            }
        case STATS_LOADING:
            return {
                ...state,
                statsLoading: true,
            }
        case GET_STATS:
            return {
                ...state,
                stats: action.payload,
                statsLoading: false,
            }
        case CONTACT:
            return {
                ...state,
                contact_res: action.payload,
                authLoading: false,
            }
        case CONTACT_FAIL:
            return {
                ...state,
                authLoading: false,
            }
        case STATS_ERROR:
            return {
                ...state,
                statsLoading: false,
            }

        case UPDATE_CREDITS:
            return {
                ...state,
                credits: action.payload
            }
        case RESET:
            return {
                ...state,
                resetSuccess: true,
                resetres: action.payload,
                authLoading: false
            }
        case RESET_LOADING:
            return {
                ...state,
                r_loading: true
            }
        case UPDATE_USER:
            return {
                ...state,
                user: action.payload,
                authLoading: false
            }
        case FORGOT_PASSWORD:
            return {
                ...state,
                fgtpres: action.payload,
                authLoading: false
            }

        case FORGOT_PASSWORD_FAIL:
        case PASSWORD_FAIL:
        case USER_FAIL:
        case RESET_FAIL:
            return {
                ...state,
                authLoading: false,
                resetSuccess: null
            }
        case UPDATE_PASSWORD:
            return {
                ...state,
                authLoading: false,
                success: true
            }

        case CLEAR_UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                success: false
            }

        case CLEAR_RESET_SUCCESS:
            return {
                ...state,
                resetSuccess: false
            }

        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            localStorage.setItem("token", JSON.stringify(action.payload.token));
            axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`;
            return {
                ...state,
                isAuthenticated: true,
                authLoading: false,
                token: action.payload.token,
                user: action.payload.user,
                credits: action.payload.credits
            }

        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REGISTER_FAIL:
            localStorage.removeItem('token');
            delete axios.defaults.headers.common['Authorization'];
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                authLoading: false,
                user: null,
                credits: null,
                stats: null,
                isLoading: false
            }

        default:
            return state;
    }
}