import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import groupsReducer from './groupsReducer';
import smsReducer from './smsReducer';
import contactsReducer from './contactsReducer';

export default combineReducers({
    error: errorReducer,
    auth: authReducer,
    groups: groupsReducer,
    sms: smsReducer,
    contacts: contactsReducer
});