import React, { useState } from 'react'
import { Nav, NavItem, Collapse, Button, } from 'reactstrap';
import {
    NavLink
} from "react-router-dom";
const SideNav = ({ url }) => {
    const [groupOpen, setGroupOpen] = useState(false);
    const [sendOpen, setSendOpen] = useState(false);

    const toggleGroup = () => {
        setGroupOpen(!groupOpen);
        if (sendOpen) {
            setSendOpen(false);
        }
    }
    const toggleSend = () => {
        setSendOpen(!sendOpen);
        if (groupOpen) {
            setGroupOpen(false);
        }
    }
    return (
        <>
            <Nav className="side-nav mt-3" vertical>
                <NavItem className="d_h">
                    <NavLink to={url} className="nav-link d-flex align-items-center"><ion-icon name="apps"></ion-icon> <span className="ml-2">Dashboard</span> </NavLink>
                </NavItem>
                <NavItem>
                    <Button color="link" onClick={toggleSend} className="nav-link d-flex align-items-center"><ion-icon name="mail"></ion-icon> <span className="ml-2">Send</span></Button>
                    <Collapse isOpen={sendOpen} className="pl-3">

                        <NavLink exact to={`${url}/send/single`} className="nav-link" activeClassName="active">Send Single</NavLink>
                        <NavLink to={`${url}/send/bulk`} className="nav-link" activeClassName="active">Send Bulk</NavLink>
                    </Collapse>
                </NavItem>
                <NavItem>
                    <Button color="link" onClick={toggleGroup} className={`nav-link d-flex align-items-center`}><ion-icon name="people"></ion-icon> <span className="ml-2">Groups</span> </Button>
                    <Collapse isOpen={groupOpen} className="pl-3">
                        <NavLink to={`${url}/group/create`} className="nav-link" activeClassName="active">Create Group</NavLink>
                        <NavLink to={`${url}/groups`} className="nav-link" activeClassName="active">All Groups</NavLink>
                    </Collapse>
                </NavItem>
                <NavItem>
                    <NavLink to={`${url}/sent`} className="nav-link d-flex align-items-center" activeClassName="active"><ion-icon name="chatbox"></ion-icon> <span className="ml-2">Sent</span> </NavLink>
                </NavItem>
            </Nav>
        </>
    )
}

export default SideNav
