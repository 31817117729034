import React from 'react'

const Footer = () => {
    return (
        <div className="mt-5">

        </div>
    )
}

export default Footer
