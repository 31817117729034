import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import {
    Table,
    Card,
    CardHeader,
    CardBody, Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input
} from 'reactstrap';
import { getContacts, createContacts, removeContacts, importFile } from '../../actions/contactsActions';
import { Link, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import Eg from '../../assets/example.xlsx'
import { Helmet } from 'react-helmet';

const Contacts = ({ contactsState: { contacts, contacts_Loading, add_Loading, importLoading, success }, getContacts, createContacts, removeContacts, error, importFile }) => {

    let { slug } = useParams();
    let contactsSelect = useRef();
    const customStyles = {
        multiValue: (styles, { data }) => {

            return {
                ...styles,
                backgroundColor: "#0bb783",
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: 'white',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: 'white',
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }), placeholder: (styles) => ({
            ...styles,
            fontSize: 12
        })
    }

    const [cntcts, setCntcts] = useState([])

    const handleChange = (value) => {
        setCntcts(value);
    };

    useEffect(() => {
        getContacts(slug)
    }, [])
    useEffect(() => {
        if (success) {
            setCntcts([]);
            contactsSelect.current.state.value = [];
            setModal(false)
        }
    }, [success])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            contacts: cntcts.map(contact => contact.value),
            slug
        }
        createContacts(data);
    }

    const [document, setDocument] = useState('')
    const handleFileSubmit = (e) => {
        e.preventDefault();
        let file = new FormData();
        file.append('file', document);
        file.append('slug', slug);
        importFile(file);
    }

    const handleRemove = (id) => {
        removeContacts(id);
    }


    const [modal, setModal] = useState(false);

    const toggle = () => {

        setModal(!modal)
        if (!modal) {
            setDocument('')
        }
    };

    const closeBtn = <button className="close" onClick={toggle}><ion-icon style={{ color: "#000" }} name="close-outline"></ion-icon></button>;

    return (
        <div>
            <Helmet>
                <title>{slug.replace(/-/g, " ")} contacts</title>
            </Helmet>
            <div className="heading mt-5">
                <h2>Contacts</h2>
                <p className="my-4"><Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/groups">Groups</Link> / Contacts ({slug.replace(/-/g, " ")})</p>
            </div>
            <Card className="card-custom">
                <CardHeader>
                    <div className="card-title mt-4">
                        <h3 className="card-label">
                            {slug.replace(/-/g, " ")} contacts
                        </h3>
                        <p>Add contacts below, make sure your number starts with 27 as 0, e.g. 27765921951 instead of 0765921951</p>
                    </div>
                    <div class="card-toolbar">
                        <Button color="link" onClick={toggle} className="d-flex align-items-center pl-0"><ion-icon name="push-outline" style={{ color: "#1bc5bd", transform: "rotate(180deg)" }}></ion-icon> <span className="ml-2">Import</span></Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="groupName">Contacts</Label>
                            <CreatableSelect
                                ref={contactsSelect}
                                styles={customStyles}
                                isClearable
                                onChange={handleChange}
                                isMulti
                                placeholder="Press enter after typing"
                            />
                            {error.message.contacts && <div className="s_e d-flex align-items-center">{error.message.contacts}  <ion-icon name="information-circle-outline"></ion-icon></div>}
                            <span className="form-text text-muted">You can enter multiple contacts. The contact should be in this format 27765921951</span>
                        </FormGroup>

                        <Button color="primary" type="submit">{add_Loading ? "Loading..." : "Add"}</Button>
                    </Form>
                    <Table borderless responsive>
                        <thead>
                            <tr>
                                <th className="c_h">Contact ({contacts.length})</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts_Loading && contacts ? <span>Loading...</span> :
                                contacts.length ? (
                                    contacts.map((contact, index) =>
                                    (
                                        <tr key={index}>
                                            <td>
                                                <div className="contact d-flex align-items-center">
                                                    <ion-icon name="call-outline"></ion-icon>
                                                    <span className="ml-3">
                                                        {contact.contact}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-center"><button onClick={() => handleRemove(contact.id)} className="btn"><ion-icon name="trash-outline"></ion-icon></button></td>
                                        </tr>
                                    )
                                    )) : <div className="text-muted">This group has no contacts</div>
                            }
                        </tbody>
                    </Table>

                </CardBody>
            </Card>



            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} close={closeBtn}>Import Contacts file</ModalHeader>
                <Form onSubmit={handleFileSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="groupName">File</Label>
                            <Input type="file" name="name" id="groupName" placeholder="Enter group name" onChange={(e) => setDocument(e.target.files[0])} />
                            {error.message.file && <div className="s_e d-flex align-items-center mt-2">{error.message.file}</div>}
                            <span className="form-text text-muted mt-2">Please select an exel file with your contacts, see <a href={Eg}>Example File</a></span>
                        </FormGroup>


                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">{importLoading ? "Loading..." : "Add"}</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    contactsState: state.contacts,
    error: state.error
})

export default connect(mapStateToProps, { getContacts, createContacts, removeContacts, importFile })(Contacts)
