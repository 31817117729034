import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert
} from 'reactstrap';
import { connect } from 'react-redux';
import { login } from '../../actions/authActions';
import Auth from '../../assets/auth.svg'
import { Helmet } from "react-helmet";

const Login = ({ login, loading, error, isAuthenticated }) => {
    const history = useHistory();
    const [user, setUser] = useState({
        email: '',
        password: '',
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        login(user);
    }
    const onChange = e => setUser({ ...user, [e.target.name]: e.target.value });
    useEffect(() => {
        let url = '/dashboard';


        if (history.location.state) {
            url = history.location.state.from.pathname
        }
        if (isAuthenticated) {
            history.push(url);
        }

    }, [isAuthenticated])


    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Container fluid={true}>
                <Row>
                    <Col md="4" className="authAside d-flex align-items-center">
                        <div className="p-5">
                            <img src={Auth} alt="auth" width="100%" />
                        </div>
                    </Col>
                    <Col >
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "95vh" }}>

                            <div className="login-form">
                                <div className="mb-3">
                                    <h3>Welcome to notifyZA</h3>
                                    <span className="text-muted">New Here?
									<Link to="/register" className="text-primary"> Create an Account</Link></span>
                                </div>
                                {error.message.login && <Alert color="danger">{error.message.login}</Alert>}
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input type="email" name="email" placeholder="email" onChange={onChange} />

                                    </FormGroup>
                                    <FormGroup>
                                        <div className="d-flex justify-content-between">
                                            <Label for="password">Password</Label>
                                            <Link to="/forgot-password">Forgot Password</Link>
                                        </div>
                                        <Input type="password" name="password" placeholder="password" onChange={onChange} />

                                    </FormGroup>
                                    <Button color="primary" type="submit">{loading ? "Loading..." : "Login"}</Button>
                                </Form>
                            </div>
                        </div>


                        <div className="text-center">
                            <Link to="/">Home</Link>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

const mapStateToProps = state => ({
    error: state.error,
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.authLoading
})

export default connect(mapStateToProps, { login })(Login)
