import React, { useEffect, useState } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import { connect } from 'react-redux';
import Home from './Home';
import Groups from './/groups/Groups';
import { Container } from 'reactstrap';
import Navigation from './Navigation';
import SideNav from './SideNav';
import CreateGroup from './groups/CreateGroup';
import Single from './send/Single';
import Bulk from './send/Bulk';
import Contacts from './groups/Contacts';
import Profile from './profile/Profile';
import Edit from './groups/Edit';
import Sent from './send/Sent';
import Message from './send/Message';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import Footer from './Footer';
ReactGA.initialize('UA-183879346-2'); // add your tracking id here.

const Dashboard = ({ auth: { user } }) => {
    let history = useHistory();
    let { path, url } = useRouteMatch();
    const [show, setShow] = useState(false)

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        if (show) {
            setShow(false);
        }
    }, [history.location.key])

    return (
        <div>
            <Helmet>
                <title>Dashboard</title>
                <meta name="description" content="Bulk SMS Client to Connect, engage with your customers" />
            </Helmet>
            <div>
                <Navigation show={show} setShow={setShow} />
                <div className="d-flex">
                    <div className={`aside ${show ? "show" : ""}`}>
                        <SideNav url={url} />
                    </div>
                    <div className="aside-overlay" onClick={() => setShow(false)} style={{ display: show ? "block" : "none" }}></div>
                    <Container className="">
                        <div className="">
                            <Switch>
                                <Route exact path={path}>
                                    <Home />
                                </Route>
                                <Route path={`${path}/groups`}>
                                    <Groups />
                                </Route>
                                <Route path={`${path}/group/create`}>
                                    <CreateGroup />
                                </Route>
                                <Route path={`${path}/group/:slug/contacts`}>
                                    <Contacts />
                                </Route>
                                <Route path={`${path}/group/:slug`}>
                                    <Edit />
                                </Route>
                                <Route path={`${path}/send/single`}>
                                    <Single />
                                </Route>
                                <Route exact path={`${path}/sent`}>
                                    <Sent />
                                </Route>
                                <Route path={`${path}/sent/message/:id`}>
                                    <Message />
                                </Route>
                                <Route path={`${path}/profile`}>
                                    <Profile />
                                </Route>
                                <Route path={`${path}/send/bulk`}>
                                    <Bulk />
                                </Route>
                            </Switch>
                        </div>
                    </Container>
                </div>
            </div>


            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})
export default connect(mapStateToProps)(Dashboard);
