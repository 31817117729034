import {
    SEND_LOADING,
    SEND_SUCCESS,
    SEND_ERROR,
    MESSAGES_LOADING,
    GET_MESSAGES,
    MESSAGE_LOADING,
    GET_MESSAGE,
    MESSAGES_ERROR,
    MESSAGE_ERROR,
    CLEAR_SEND_SUCCESS
} from '../actions/types';

const initialState = {
    send_Loading: false,
    messages_loading: false,
    message_loading: false,
    messages: [],
    message: [],
    report: [],
    success: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SEND_LOADING:
            return {
                ...state,
                send_Loading: true
            }
        case MESSAGE_LOADING:
            return {
                ...state,
                message_loading: true
            }
        case MESSAGES_LOADING:
            return {
                ...state,
                messages_loading: true
            }
        case SEND_SUCCESS:
            return {
                ...state,
                report: action.payload,
                send_Loading: false,
                success: true
            }
        case GET_MESSAGES:
            return {
                ...state,
                messages: action.payload,
                messages_loading: false
            }
        case GET_MESSAGE:
            return {
                ...state,
                message: action.payload,
                message_loading: false
            }
        case SEND_ERROR:
            return {
                ...state,
                send_Loading: false
            }
        case MESSAGES_ERROR:
            return {
                ...state,
                messages_loading: false
            }
        case MESSAGE_ERROR:
            return {
                ...state,
                message_loading: false
            }
        case CLEAR_SEND_SUCCESS:
            return {
                ...state,
                success: false
            }
        default:
            return state;
    }
}