
import { useEffect } from 'react';
import store from './store';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { loadUser } from './actions/authActions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import './App.css';
import Dashboard from './componets/Dashboard';
import Login from './componets/auth/Login';
import Register from './componets/auth/Register';
import Welcome from './componets/Welcome';
import Forgot from './componets/auth/Forgot';
import Protected from './componets/Protected';
import NoMatch from './componets/NoMatch';
import Reset from './componets/auth/Reset';
import { connect } from 'react-redux';

function App({ loading, isAuthenticated }) {

  useEffect(() => {
    store.dispatch(loadUser());
  }, [])


  if (loading && !isAuthenticated) {
    return <div style={{ height: '100vh' }} className="d-flex align-items-center justify-content-center">
      <div>
        Loading...
      </div>
    </div>
  }

  return (
    <Router>
      <ToastContainer
        hideProgressBar
      />
      <Switch>
        <Route exact path="/">
          <Welcome />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/forgot-password">
          <Forgot />
        </Route>
        <Route path="/password/reset/:token">
          <Reset />
        </Route>
        <Protected path="/dashboard" component={Dashboard} />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>

    </Router>
  );
}
const mapStateTopProps = state => ({
  loading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated
})
export default connect(mapStateTopProps)(App);
