import React, { useEffect } from 'react'
import {
    Card,
    CardBody,
    Row,
    Col,
    CardTitle,
    CardSubtitle,
    CardHeader
} from 'reactstrap';
import { connect } from 'react-redux';
import { getStats } from '../actions/authActions';
import moment from 'moment';
import StatsChart from './StatsChart';
import { Link } from 'react-router-dom';

const Home = ({ loading, stats, getStats, credits, user }) => {

    let welcome;
    let date = new Date();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    if (minute < 10) {
        minute = "0" + minute;
    }
    if (second < 10) {
        second = "0" + second;
    }
    if (hour < 12) {
        welcome = "Good morning";
    } else if (hour < 17) {
        welcome = "Good afternoon";
    } else {
        welcome = "Good evening";
    }
    useEffect(() => {
        getStats();
    }, [])


    return (
        <div>
            <div className="heading mt-5">
                <h2>Dashboard</h2>
                <p>{welcome}, <span style={{ color: '#0BB783' }}>{user.name}</span>. {moment().format('LL')}</p>

            </div>
            <Row>
                <Col mg="3" className="mt-3">
                    <Card className="card-custom">
                        <Link to="/dashboard/sent" style={{ color: 'transparent' }}>

                            <CardBody>
                                <CardTitle tag="h5" className="d-flex align-items-center">
                                    <ion-icon name="chatbox-outline"></ion-icon>
                                    <span className="ml-2" style={{ color: 'black' }}>
                                        Messages
                                    </span>

                                </CardTitle>
                                {loading ? <span className="text-muted">Loading...</span> :
                                    <CardSubtitle tag="h6" className="mb-2 text-muted">{stats && stats.messages}</CardSubtitle>
                                }
                            </CardBody>
                        </Link>

                    </Card>
                </Col>
                <Col mg="3" className="mt-3">
                    <Card className="card-custom">
                        <Link to="/dashboard/groups" style={{ color: 'transparent' }}>
                            <CardBody>
                                <CardTitle tag="h5" className="d-flex align-items-center">
                                    <ion-icon name="people-outline"></ion-icon>
                                    <span className="ml-2" style={{ color: 'black' }}>
                                        Groups
                                    </span>
                                </CardTitle>
                                {loading ? <span className="text-muted">Loading...</span>
                                    : <CardSubtitle tag="h6" className="mb-2 text-muted">{stats && stats.groups}</CardSubtitle>

                                }
                            </CardBody>
                        </Link>

                    </Card>
                </Col>
                <Col mg="3" className="mt-3">
                    <Card className="card-custom">
                        <CardBody>
                            <CardTitle tag="h5" className="d-flex align-items-center">

                                <ion-icon name="chatbubbles-outline"></ion-icon>
                                <span className="ml-2">
                                    SMSes
                                </span>

                            </CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">{credits.credits}</CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


            <Card className="card-custom mt-5">
                <CardHeader>
                    <div className="card-title">
                        <h5 className="card-label">
                            Statistics
                        </h5>
                    </div>
                </CardHeader>
                <CardBody className="p-0">
                    {loading ? <p className="p-5">Loading...</p> :

                        stats && <StatsChart stats={stats.data} />
                    }
                </CardBody>
            </Card>
        </div>
    )
}
const mapStateToProps = state => ({
    loading: state.auth.statsLoading,
    credits: state.auth.credits,
    stats: state.auth.stats,
    user: state.auth.user
})
export default connect(mapStateToProps, { getStats })(Home)
