import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import {
    Table,
    Card,
    CardHeader,
    CardBody
} from 'reactstrap';
import { connect } from 'react-redux';
import { getMessages } from '../../actions/smsActions';
import moment from 'moment'
import { Helmet } from 'react-helmet';


const Sent = ({ smsState: { messages_loading, messages }, getMessages }) => {


    useEffect(() => {
        getMessages();
    }, [])

    return (
        <div>
            <Helmet>
                <title>Sent</title>
            </Helmet>
            <div className="heading mt-5">
                <h2>Messages</h2>
                <p className="my-4"><Link to="/dashboard">Dashboard</Link> / sent</p>
            </div>
            <Card className="card-custom">
                <CardHeader>
                    <div className="card-title mt-4">
                        <h3 className="card-label">
                            Sent Messages
                           </h3>
                    </div>
                </CardHeader>
                <CardBody>

                    <Table className="mt-3" borderless responsive >
                        <thead>
                            <tr>
                                <th className="c_h">Message ({messages.length})</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {messages_loading && messages ? <div>Loading...</div> :
                                messages.length ? (
                                    messages.map((message, index) =>
                                    (
                                        <tr key={index}>
                                            <td>
                                                <div className="contact d-flex align-items-center">
                                                    <div>
                                                        <ion-icon name="chatbubble-outline"></ion-icon>
                                                    </div>
                                                    <div className="ml-2">
                                                        {message.message.length > 80 ? message.message.slice(0, 80) + '...' : message.message} <span style={{ color: '#0BB783' }}>to {message.to}</span>
                                                        {/* <br />
                                                        Encoding: TEXT */}
                                                    </div>
                                                </div>
                                                <small className="text-muted">{moment(message.created_at).fromNow()}</small>
                                            </td>
                                            <td className="text-center">
                                                <Link to={`/dashboard/sent/message/${message.access_id}`} className="btn"><ion-icon name="information-circle-outline"></ion-icon></Link>
                                            </td>
                                        </tr>
                                    )
                                    )) : <div className="text-muted">You have no sent messages</div>
                            }
                        </tbody>
                    </Table>

                </CardBody>
            </Card>
        </div>
    )
}

const mapStateToProps = state => ({
    smsState: state.sms
})

export default connect(mapStateToProps, { getMessages })(Sent)
