import React, { useState, useEffect } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updatePassword, updateUser } from '../../actions/authActions';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Profile = ({ authState: { user, credits, authLoading, success }, updatePassword, updateUser, error }) => {

    const [activeTab, setActiveTab] = useState('1');
    const [passwordData, setPasswordData] = useState({
        cpassword: '',
        password: '',
        password_confirmation: ''
    })

    const [name, setName] = useState(user.name)
    const [addon, setAddOn] = useState(user.addon)

    const onChange = (e) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value })
    }

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    const handlePasswordUpdate = (e) => {
        e.preventDefault();

        updatePassword(passwordData);
    }
    const handleUserUpdate = (e) => {
        e.preventDefault();

        updateUser({ name, addon });
    }

    useEffect(() => {
        if (success) {
            setPasswordData({
                cpassword: '',
                password: '',
                password_confirmation: ''
            })
        }
    }, [success])

    return (
        <div>
            <Helmet>
                <title>{user.name}'s profile</title>
            </Helmet>
            <div className="heading mt-5">
                <h2>Your Profile</h2>
                <p className="my-4"><Link to="/dashboard">Dashboard</Link> / Profile</p>

            </div>
            <Card className="card-custom">
                <CardHeader>
                    <div className="card-title mt-4">
                        <h3 className="card-label">
                            Profile
                        </h3>
                        <p>Update your account information</p>
                    </div>
                </CardHeader>
                <CardBody>
                    <div>

                        <ListGroup flush>
                            <ListGroupItem disabled>{user.name}</ListGroupItem>
                            <ListGroupItem>{user.email}</ListGroupItem>
                            <ListGroupItem>{credits.credits} SMSes</ListGroupItem>
                        </ListGroup>

                    </div>

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' }, 'pl-0')}
                                onClick={() => { toggle('1'); }}
                            >
                                Personal Information
                        </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' }, 'c_P_B')}
                                onClick={() => { toggle('2'); }}
                            >
                                Change Password
                        </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="mt-5">
                        <TabPane tabId="1">
                            <div >
                                <Form onSubmit={handleUserUpdate}>
                                    <FormGroup>
                                        <Label for="name">Name</Label>
                                        <Input type="text" name="name" id="name" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} invalid={error.message.name} />
                                        {error.message.name && <FormFeedback>{error.message.name}</FormFeedback>}
                                        <span className="form-text text-muted">e.g. company name or your name</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="addon">Add ons</Label>
                                        <Input type="text" name="addon" id="addon" placeholder={`(${user.name}, reply STOP to optout)`} value={addon} onChange={(e) => setAddOn(e.target.value)} invalid={error.message.addon} />
                                        {error.message.addon && <FormFeedback>{error.message.addon}</FormFeedback>}
                                        <span className="form-text text-muted">This will be added at the end of every message you send, e.g. {user.name}, reply STOP to optout</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input type="text" name="email" id="email" placeholder="Enter email" value={user.email} disabled />
                                        <span className="form-text text-muted">Your email is used as you username when login in, it can't be updated</span>
                                    </FormGroup>
                                    <Button color="primary" type="submit" disabled={authLoading}>{authLoading ? "Loading..." : "Update"}</Button>
                                </Form>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div>
                                <Form onSubmit={handlePasswordUpdate}>
                                    <FormGroup>
                                        <div className="d-flex justify-content-between">
                                            <Label for="cpassword">Current Password</Label>
                                            <Link to="/forgot-password">Forgot Password</Link>
                                        </div>
                                        <Input type="password" name="cpassword" id="cpassword" value={passwordData.cpassword} onChange={onChange} placeholder="Enter your current password" invalid={error.message.cpassword} />
                                        {error.message.cpassword && <FormFeedback>{error.message.cpassword}</FormFeedback>}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="password">New Password</Label>
                                        <Input type="password" name="password" id="password" value={passwordData.password} onChange={onChange} placeholder="Enter password" invalid={error.message.password} />
                                        {error.message.password && <FormFeedback>{error.message.password}</FormFeedback>}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="confirm_password">Confirm Password</Label>
                                        <Input type="password" name="password_confirmation" value={passwordData.password_confirmation} onChange={onChange} id="confirm_password" placeholder="Confirm password" />
                                    </FormGroup>
                                    <Button color="primary" type="submit">{authLoading ? "Loading..." : "Update"}</Button>
                                </Form>
                            </div>
                        </TabPane>
                    </TabContent>

                </CardBody>
            </Card>

        </div>
    )
}

const mapStateToProps = state => ({
    authState: state.auth,
    error: state.error
})

export default connect(mapStateToProps, { updatePassword, updateUser })(Profile)
