import React from 'react'
import { Link } from 'react-router-dom'
import NotFound from '../assets/404.svg'

const NoMatch = () => {
    return (
        <div className="container" >
            <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                <div className="text-center">
                    <div>
                        <img src={NotFound} alt="404" width="300" />
                    </div>
                    <p className="mt-4">Oops Page not Found!</p>
                    <Link to='/' className="btn btn-primary mt-2">Home</Link>
                </div>
            </div>

        </div>
    )
}

export default NoMatch
