import {
    CONTACTS_LOADING,
    GET_CONTACTS,
    CREATE_CONTACTS,
    DELETE_CONTACTS,
    CONTACTS_ERROR,
    Add_LOADING,
    CLEAR_CONTACT_SUCCESS,
    IMPORT_LOADING,
    IMPORT_CONTACTS
} from '../actions/types';

const initialState = {
    contacts_Loading: false,
    add_Loading: false,
    importLoading: false,
    contacts: [],
    success: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CONTACTS_LOADING:
            return {
                ...state,
                contacts_Loading: true
            }
        case Add_LOADING:
            return {
                ...state,
                add_Loading: true
            }
        case IMPORT_LOADING:
            return {
                ...state,
                importLoading: true
            }
        case GET_CONTACTS:
            return {
                ...state,
                contacts: action.payload,
                contacts_Loading: false
            }
        case CREATE_CONTACTS:
            return {
                ...state,
                contacts: state.contacts.concat(action.payload),
                add_Loading: false,
                success: true
            }

        case IMPORT_CONTACTS:
            return {
                ...state,
                // contacts: state.contacts.concat(action.payload),
                importLoading: false,
                contacts: action.payload,
                success: true
            }

        case DELETE_CONTACTS:
            return {
                ...state,
                contacts: state.contacts.filter(contact => contact.id !== action.payload),
                contacts_Loading: false
            }
        case CONTACTS_ERROR:
            return {
                ...state,
                contacts_Loading: false,
                add_Loading: false,
                importLoading: false
            }
        case CLEAR_CONTACT_SUCCESS:
            return {
                ...state,
                success: false
            }
        default:
            return state;
    }
}