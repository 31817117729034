import axios from 'axios';
import { returnErrors } from './errorActions'
import {
    SEND_LOADING,
    SEND_SUCCESS,
    SEND_ERROR,
    UPDATE_CREDITS,
    MESSAGES_LOADING,
    GET_MESSAGES,
    MESSAGES_ERROR,
    GET_MESSAGE,
    MESSAGE_LOADING,
    MESSAGE_ERROR,
    CLEAR_ERRORS,
    CLEAR_SEND_SUCCESS
} from '../actions/types';
import { toast } from 'react-toastify';

const url = 'https:///backend.notifyza.co.za/api';

// Send Single
export const sendSingle = (data) => async dispatch => {

    // Loading
    dispatch({ type: SEND_LOADING });

    try {
        const res = await axios.post(`${url}/v2/send/single`, data);
        dispatch({
            type: SEND_SUCCESS,
            payload: res.data
        })

        dispatch({
            type: UPDATE_CREDITS,
            payload: res.data
        })


        toast.success("Message is on its way", { position: "top-center" });
        setTimeout(() => dispatch({ type: CLEAR_SEND_SUCCESS }), 5000);
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'SEND_ERROR'));
        dispatch({
            type: SEND_ERROR
        })

        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}
// Send Bulk

export const sendBulk = (data) => async dispatch => {

    //  Loading
    dispatch({ type: SEND_LOADING });

    try {
        const res = await axios.post(`${url}/v2/send/bulk`, data);
        dispatch({
            type: SEND_SUCCESS,
            payload: res.data
        })

        dispatch({
            type: UPDATE_CREDITS,
            payload: res.data
        })


        toast.success("Message is on its way", { position: "top-center" });
        setTimeout(() => dispatch({ type: CLEAR_SEND_SUCCESS }), 5000);
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'SEND_ERROR'));
        dispatch({
            type: SEND_ERROR
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}


// Get Messages

export const getMessages = () => async dispatch => {

    //  Loading
    dispatch({ type: MESSAGES_LOADING });

    try {
        const res = await axios.get(`${url}/v2/messages`);
        dispatch({
            type: GET_MESSAGES,
            payload: res.data
        })
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'MESSAGES_ERROR'));
        dispatch({
            type: MESSAGES_ERROR
        })
    }
}
// Get Message

export const getMessage = (id) => async dispatch => {

    //  Loading
    dispatch({ type: MESSAGE_LOADING });
    try {
        const res = await axios.get(`${url}/v2/message/${id}`);
        dispatch({
            type: GET_MESSAGE,
            payload: res.data
        })
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'MESSAGE_ERROR'));
        dispatch({
            type: MESSAGE_ERROR
        })
    }


}