

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const CREATE_GROUPS = 'CREATE_GROUPS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const GET_CONTACTS = 'GET_CONTACTS';
export const CONTACTS_LOADING = 'CONTACTS_LOADING';
export const CREATE_CONTACTS = 'CREATE_CONTACTS';
export const DELETE_CONTACTS = 'DELETE_CONTACTS';
export const DELETE_GROUP = 'DELETE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const CONTACTS_ERROR = 'CONTACTS_ERROR';
export const GROUPS_ERROR = 'GROUPS_ERROR';
export const SEND_LOADING = 'SEND_LOADING';
export const SEND_SUCCESS = 'SEND_SUCCESS';
export const SEND_ERROR = 'SEND_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const Add_LOADING = 'Add_LOADING';
export const CLEAR_GROUP = 'CLEAR_GROUP';
export const SET_GROUP = 'SET_GROUP';
export const GET_GROUPS_LIST = 'GET_GROUPS_LIST';
export const UPDATE_CREDITS = 'UPDATE_CREDITS';
export const MESSAGES_LOADING = 'MESSAGES_LOADING';
export const GET_MESSAGES = 'GET_MESSAGES';
export const MESSAGES_ERROR = 'MESSAGES_ERROR';
export const GET_MESSAGE = 'GET_MESSAGE';
export const MESSAGE_LOADING = 'MESSAGE_LOADING';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const PASSWORD_FAIL = 'PASSWORD_FAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_FAIL = 'USER_FAIL';
export const CLEAR_GROUP_SUCCESS = 'CLEAR_GROUP_SUCCESS';
export const CLEAR_CONTACT_SUCCESS = 'CLEAR_CONTACT_SUCCESS';
export const CLEAR_SEND_SUCCESS = 'CLEAR_SEND_SUCCESS';
export const CLEAR_UPDATE_PASSWORD_SUCCESS = 'CLEAR_UPDATE_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FIND = 'FIND';
export const FIND_FAIL = 'FIND_FAIL';
export const RESET_LOADING = 'RESET_LOADING';
export const RESET = 'RESET';
export const RESET_FAIL = 'RESET_FAIL';
export const CLEAR_RESET_SUCCESS = 'CLEAR_RESET_SUCCESS';
export const STATS_LOADING = 'STATS_LOADING';
export const GET_STATS = 'GET_STATS';
export const STATS_ERROR = 'STATS_ERROR';
export const CONTACT = 'CONTACT';
export const CONTACT_FAIL = 'CONTACT_FAIL';
export const IMPORT_LOADING = 'IMPORT_LOADING';
export const IMPORT_CONTACTS = 'IMPORT_CONTACTS';
export const GROUPS_LOADING = 'GROUPS_LOADING';
export const GET_GROUPS = 'GET_GROUPS';