import axios from 'axios';
import { returnErrors } from './errorActions'
import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    AUTH_LOADING,
    UPDATE_PASSWORD,
    PASSWORD_FAIL,
    UPDATE_USER,
    USER_FAIL,
    CLEAR_ERRORS,
    CLEAR_UPDATE_PASSWORD_SUCCESS,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAIL,
    RESET,
    RESET_FAIL,
    CLEAR_RESET_SUCCESS,
    STATS_LOADING,
    GET_STATS,
    STATS_ERROR,
    CONTACT,
    CONTACT_FAIL
} from '../actions/types';
import { toast } from 'react-toastify';

const url = 'https:///backend.notifyza.co.za/api';

// check token and LoadUser
export const loadUser = () => async (dispatch, getState) => {

    // User Loading
    dispatch({ type: USER_LOADING });

    // Get token from localstorage
    // const token = localStorage.getItem('token');
    // const token = getState().auth.token;
    const token = JSON.parse(localStorage.getItem('token') || null)

    // if token, add to headers
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }

    try {
        const res = await axios.get(`${url}/v2/user`);
        dispatch({
            type: USER_LOADED,
            payload: res.data
        })
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({
            type: AUTH_ERROR
        })
    }
}

// get user statistics
export const getStats = () => async (dispatch) => {

    // Loading
    dispatch({ type: STATS_LOADING });

    try {
        const res = await axios.get(`${url}/v2/user/stats`);
        dispatch({
            type: GET_STATS,
            payload: res.data
        })
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({
            type: STATS_ERROR
        })
    }
}


// Register user

export const register = (user) => async dispatch => {
    // Auth Loading
    dispatch({ type: AUTH_LOADING });
    try {
        const res = await axios.post(`${url}/register`, user);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        })
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'REGISTER_FAIL'));
        dispatch({
            type: REGISTER_FAIL
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}

// Login User

export const login = (user) => async dispatch => {
    // Auth Loading
    dispatch({ type: AUTH_LOADING });
    try {
        const res = await axios.post(`${url}/login`, user);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        })
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'LOGIN_FAIL'));
        dispatch({
            type: LOGIN_FAIL
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}

// Update Password

export const updatePassword = (data) => async dispatch => {
    // Auth Loading
    dispatch({ type: AUTH_LOADING });
    try {
        const res = await axios.post(`${url}/v2/password`, data);
        dispatch({
            type: UPDATE_PASSWORD,
            payload: res.data
        })

        toast.success("Password Updated");
        setTimeout(() => dispatch({ type: CLEAR_UPDATE_PASSWORD_SUCCESS }), 5000);
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'PASSWORD_FAIL'));
        dispatch({
            type: PASSWORD_FAIL
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}


// Update User

export const updateUser = (data) => async dispatch => {
    // Auth Loading
    dispatch({ type: AUTH_LOADING });
    try {
        const res = await axios.post(`${url}/v2/user`, data);
        dispatch({
            type: UPDATE_USER,
            payload: res.data
        })

        toast.success("User Updated");
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'USER_FAIL'));
        dispatch({
            type: USER_FAIL
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}

// Forgot Password
export const forgotPassword = (email) => async dispatch => {
    // Auth Loading
    dispatch({ type: AUTH_LOADING });
    try {
        const res = await axios.post(`${url}/forgotPassword`, email);
        dispatch({
            type: FORGOT_PASSWORD,
            payload: res.data
        })

        // toast.success("User Updated");
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'FORGOT_PASSWORD_FAIL'));
        dispatch({
            type: FORGOT_PASSWORD_FAIL
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}


// Forgot Password
export const resetPassword = (data) => async dispatch => {
    // Auth Loading
    dispatch({ type: AUTH_LOADING });
    try {
        const res = await axios.post(`${url}/reset`, data);
        dispatch({
            type: RESET,
            payload: res.data
        })

        // toast.success("User Updated");
        setTimeout(() => dispatch({ type: CLEAR_RESET_SUCCESS }), 5000);
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'RESET_FAIL'));
        dispatch({
            type: RESET_FAIL
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}

// Contact Us
export const contact = (data) => async dispatch => {
    // Auth Loading
    dispatch({ type: AUTH_LOADING });
    try {
        const res = await axios.post(`${url}/contact`, data);
        dispatch({
            type: CONTACT,
            payload: res.data
        })
        // toast.success("User Updated");
        // setTimeout(() => dispatch({ type: CLEAR_RESET_SUCCESS }), 5000);
    } catch (error) {
        dispatch(returnErrors(error.response.data, error.response.status, 'CONTACT_FAIL'));
        dispatch({
            type: CONTACT_FAIL
        })
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 5000);
    }
}



// Logout User
export const logout = () => {
    return {
        type: LOGOUT_SUCCESS
    }
}


