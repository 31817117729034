import React, { useState } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, contact } from '../actions/authActions';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Alert
} from 'reactstrap';
// import Dash from '../assets/dash.png'
import Bg from '../assets/bg.jpg'
import Mssg from '../assets/mssg.svg'
import Dash from '../assets/dash.svg'
import Logo from '../assets/LogoWhite.png';
import moment from 'moment';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-183879346-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const Welcome = ({ authSate: { isAuthenticated, authLoading, contact_res }, contact, error, logout }) => {

    let history = useHistory();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);


    const [contct, setContact] = useState({
        name: '',
        email: '',
        message: '',
    })

    const onChange = e => setContact({ ...contct, [e.target.name]: e.target.value });

    const handleSend = (e) => {
        e.preventDefault();

        contact(contct);

    }


    const handleLogout = () => {
        logout();
        history.push('/login');
    }
    return (
        <div className="welcome">
            <Helmet>
                <title>NotifyZA - Beta</title>
                <meta name="description" content="Bulk SMS Service to Connect, engage with your customers" />
            </Helmet>
            <div className="banner" style={{ backgroundImage: `linear-gradient(rgba(0 0 0),rgba(0 0 0 / 70%),rgba(0,0,0,0.5)) ,url('${Bg}')` }}>
                <Navbar dark expand="md" className="welcome-nav">
                    <Container>
                        <NavbarBrand to="/"><img src={Logo} alt="notifyZA" width="100" /></NavbarBrand>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar >
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <a href="https://sacoronavirus.co.za/" className="nav-link" target="_blank">COVID-19 updates</a>
                                </NavItem>
                            </Nav>


                            <Nav navbar>
                                {isAuthenticated ? <>

                                    <NavItem>
                                        <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <a href="#!" onClick={handleLogout} className="nav-link">Logout</a>
                                    </NavItem>

                                </> : (
                                    <>
                                        <NavItem>
                                            <NavLink to="/login" className="nav-link">Login</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="/register" className="nav-link">Register</NavLink>
                                        </NavItem>
                                    </>
                                )}
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>




                <Container>

                    <Row>
                        <Col md="5">
                            <section className="my-5">
                                <h1 className="banner-heading">Bulk SMS Service to Connect, engage with your customers.</h1>
                                <p>Send notifications, appointment reminders, seasonal greetings, alerts, informational updates or internal communication between employees or stuff</p>

                                <div className="mt-5 d-flex align-items-center">

                                    {isAuthenticated ?
                                        <Link to="/dashboard" className="btn btn-primary">Dashboard</Link> :
                                        <Link to="/register" className="btn btn-primary">Sign Up Now</Link>
                                    }
                                    <a href="#contact" className="btn btn-secondary ml-3">Contact us</a>
                                </div>
                            </section>
                        </Col>

                        <Col>
                            <div className="banner-img">
                                <img src={Dash} alt="dash" width="100%" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <section className="content mt-5">
                    <div className="text-center m_s">
                        <h1 className="mb-4">Why bulk sms messaging?</h1>
                        <p className="text-muted">It can help to increase customer engagement, promote products and services or deliver urgent notifications to your audiences.</p>

                    </div>

                    <div className="sec_2">
                        <Row className="align-items-center">
                            <Col md="5">
                                <img src={Mssg} alt="" width="100%" />
                            </Col>
                            <Col>
                                <div className="mt-5 h_l">
                                    <h1>With Bulksms you can:</h1>
                                    <ListGroup flush>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> Thank yous</ListGroupItem>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> Service notification</ListGroupItem>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> Feedback requests</ListGroupItem>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> Survay links</ListGroupItem>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> App download links</ListGroupItem>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> Reminders</ListGroupItem>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> Internal employee updates</ListGroupItem>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> Emegency Updates</ListGroupItem>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> Offers, discounts and sale alerts</ListGroupItem>
                                        <ListGroupItem className="d-flex align-items-center"><ion-icon name="chevron-forward-outline"></ion-icon> And many more</ListGroupItem>
                                    </ListGroup>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>


                <section id="contact" className="sec_1">
                    <div className="mt-5">
                        <div className="text-center">
                            <h1>Contact us</h1>
                            <p className="text-muted">Contact us for more information and pricing.</p>
                        </div>

                        <Row className="mt-5">
                            <Col md="5">
                                <div>
                                    {contact_res && <Alert color="success">{contact_res.cnt}</Alert>}
                                    <Form onSubmit={handleSend}>
                                        <FormGroup>
                                            <div className="d-flex justify-content-between">
                                                <Label for="name">Name</Label>
                                            </div>
                                            <Input type="text" name="name" id="name" placeholder="Enter your name" value={contct.name} onChange={onChange} invalid={error.message.name} />
                                            {error.message.name && <FormFeedback>{error.message.name}</FormFeedback>}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input type="email" name="email" id="email" placeholder="Enter your email" value={contct.email} onChange={onChange} invalid={error.message.email} />
                                            {error.message.email && <FormFeedback>{error.message.email}</FormFeedback>}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="message">Message</Label>
                                            <Input type="textarea" name="message" id="message" placeholder="Type a message..." value={contct.message} onChange={onChange} invalid={error.message.message} />
                                            {error.message.message && <FormFeedback>{error.message.message}</FormFeedback>}
                                        </FormGroup>
                                        <Button color="primary" type="submit">{authLoading ? "Loading..." : "Send"}</Button>
                                    </Form>
                                </div>
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center contact-link">
                                <div className="con_lft">
                                    <p className="d-flex align-items-center"><ion-icon name="mail-open"></ion-icon> <a href="mailto:support@notifyza.co.za" className="ml-3" target="_blank" rel="noreferrer">support@notifyza.co.za</a></p>
                                    <p className="d-flex align-items-center"><ion-icon name="call"></ion-icon> <span className="ml-3">079 094 6233</span> </p>
                                    <p className="d-flex align-items-center"><ion-icon name="logo-facebook"></ion-icon> <a href="https://www.facebook.com/notifyza" className="ml-3" target="_blank" rel="noreferrer">Facebook</a></p>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section>
                    <footer className="py-5">
                        <p className="d-flex align-items-center">
                            <a href="mailto:support@notifyza.co.za" target="_blank" rel="noreferrer">support@notifyza.co.za</a>
                            <a href="https://www.facebook.com/notifyza" className="ml-3" target="_blank" rel="noreferrer"><ion-icon style={{ fontSize: "30px" }} name="logo-facebook"></ion-icon></a>
                            <a href="https://wa.me/+27790946233" className="ml-3" target="_blank" rel="noreferrer"><ion-icon style={{ fontSize: "30px" }} name="logo-whatsapp"></ion-icon></a>
                        </p>
                        <p className="copyright">&copy; {moment().format("Y")}, notifyZA. All rights reserved</p>
                    </footer>
                </section>
            </Container>

        </div>
    )
}
const mapStateToProps = state => ({
    authSate: state.auth,
    error: state.error
})
export default connect(mapStateToProps, { logout, contact })(Welcome)
