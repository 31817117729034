import {
    GROUPS_LOADING,
    GET_GROUPS,
    CREATE_GROUPS,
    GROUPS_ERROR,
    DELETE_GROUP,
    UPDATE_GROUP,
    CLEAR_GROUP,
    SET_GROUP,
    GET_GROUPS_LIST,
    CLEAR_GROUP_SUCCESS
} from '../actions/types';

const initialState = {
    groups_Loading: false,
    group: [],
    list: [],
    groups: [],
    success: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GROUPS_LOADING:
            return {
                ...state,
                groups_Loading: true
            }
        case GET_GROUPS:
            return {
                ...state,
                groups: action.payload,
                groups_Loading: false
            }
        case GET_GROUPS_LIST:
            return {
                ...state,
                list: action.payload,
                groups_Loading: false
            }
        case CREATE_GROUPS:
            return {
                ...state,
                groups: [...state.groups, action.payload],
                groups_Loading: false,
                success: true
            }
        case UPDATE_GROUP:
            return {
                ...state,
                groups: state.groups.map(group => group.id === action.payload.id ? action.payload : group),
                groups_Loading: false
            }
        case DELETE_GROUP:
            return {
                ...state,
                groups: state.groups.filter(group => group.id !== action.payload),
                groups_Loading: false
            }

        case SET_GROUP: {
            return {
                ...state,
                group: action.payload
            }
        }
        case CLEAR_GROUP: {
            return {
                ...state,
                group: []
            }
        }
        case GROUPS_ERROR:
            return {
                ...state,
                groups_Loading: false
            }
        case CLEAR_GROUP_SUCCESS:
            return {
                ...state,
                success: false
            }
        default:
            return state;
    }
}