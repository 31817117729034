import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
} from 'reactstrap';
import { createGroup } from '../../actions/groupsActions';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const CreateGroup = ({ loading, error, createGroup, success }) => {

    const [group, setGroup] = useState({
        name: '',
        description: ''
    })



    const onChange = (e) => {
        setGroup({ ...group, [e.target.name]: e.target.value })
    }
    const [image, setImage] = useState({ preview: "", raw: "" });
    const handleChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = new FormData();
        data.append("name", group.name);
        data.append("description", group.description);
        data.append("featured", image.raw);
        createGroup(data);
    }

    useEffect(() => {
        if (success) {
            setGroup({
                name: '',
                description: ''
            });

            setImage({ preview: "", raw: "" });
        }
    }, [success])
    return (
        <div>
            <Helmet>
                <title>Create Group</title>
            </Helmet>
            <div className="heading mt-5">
                <h2>Add a group</h2>
                <p className="my-4"><Link to="/dashboard">Dashboard</Link> / Group / Create</p>
            </div>
            <Card className="card-custom">
                <CardHeader>
                    <div className="card-title mt-4">
                        <h3 className="card-label">
                            Create Group
                        </h3>
                    </div>
                </CardHeader>
                <CardBody>

                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="groupName">Group name</Label>
                            <Input type="text" name="name" id="groupName" value={group.name} onChange={onChange} placeholder="Enter group name" invalid={error.message.name} />
                            {error.message.name && <FormFeedback>{error.message.name}</FormFeedback>}
                            <span className="form-text text-muted">e.g. clients or employees</span>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="upload-button" className="img-upload-btn">
                                Group Image <br />
                                <br />
                                {image.preview ? (
                                    <>
                                        <img src={image.preview} alt="dummy" className="rounded mb-2" height="90px" width="90px" />
                                        <p className="img-name">{image.raw.name}</p>
                                    </>
                                ) : (
                                    <>
                                        <img src={"https://via.placeholder.com/150"} alt="..." className="rounded mb-2" height="90px" width="90px" />
                                        <p className="img-name">Upload your photo (optional)</p>
                                    </>
                                )}
                            </Label>
                            <input
                                type="file"
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={handleChange}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input type="textarea" rows="5" name="description" id="description" value={group.description} onChange={onChange} placeholder="Enter group description" invalid={error.message.description} />
                            {error.message.description && <FormFeedback>{error.message.description}</FormFeedback>}
                        </FormGroup>
                        <Button color="primary" type="submit" disabled={loading}>{loading ? "Loading..." : "Create"}</Button>
                    </Form>

                </CardBody>
            </Card>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.groups.groups_Loading,
    success: state.groups.success,
    error: state.error
})

export default connect(mapStateToProps, { createGroup })(CreateGroup)
