import React, { useState } from 'react'
import Chart from "react-apexcharts";

const StatsChart = ({ stats }) => {
    const [options, setOptions] = useState(
        {
            chart: {
                toolbar: {
                    show: false
                },
                foreColor: "#bdbdbd",
                markers: {
                    size: 5,
                    color: "#fff"

                },
                fontFamily: 'Poppins,Helvetica,sans-serif',
            },
            colors: ["#0BB783"],
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2
            },
            yaxis: {
                tickAmount: stats.max,
                min: 0,
                max: stats.max,
                title: {
                    text: 'Messages'
                }
            },
            xaxis: {
                categories: stats.months,
            },
            noData: {
                text: "No data to show yet.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: "#bdbdbd",
                    fontSize: '13px',
                    fontFamily: 'Poppins,Helvetica,sans-serif'
                }
            }
        }
    )

    const [series, setSeries] = useState(
        [
            {
                name: "Messages",
                data: stats.message_count
            }
        ]
    )
    return (
        <>
            <Chart
                options={options}
                series={series}
                type="area"
                width="100%"
                height="400"
            />
        </>
    )
}

export default StatsChart
